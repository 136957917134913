export function getCapability(action, user){
  if(user && user.capabilities){
    var capabilities = JSON.parse(JSON.stringify(user.capabilities))
    let cap = capabilities.filter(item => item.capability === action)
    if(cap.length > 0){
      return cap[0].is_able
    }
  }
  return false
}

export function isAdmin(user){
  if(user){
    return user.id < 100
  }
  return false
}
